import ReactDOM from "react-dom";
import WViewport from "../jsx/viewport.jsx.js"

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {DS} from "@common/store.js";


window.LCstorage = window.localStorage;
if (document.all && !window.localStorage) {
    window.LCstorage = {};
    window.LCstorage.removeItem = function () { };
}

class WAppContainer extends React.Component{
    render(){
        return (
            <>
                <CssBaseline/>
                <WViewport/>
            </>
        );
    }
}

class Client {
    store = null;
    registry = null;

    constructor(){

    }

    render(containerId){
        new DS("/user/")
            .bind(CCMStore, "user");

        ReactDOM.render(
            <WAppContainer client={this}></WAppContainer>, 
            document.getElementById(containerId)
        );
    }
}

export default Client;