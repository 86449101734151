import React from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class WLandingPage extends React.Component {
    render(){
        return (
            <>
                <div className="paper">
                    <div className="landing-block">
                        <Typography variant="h6" align="center">
                            Kittens Game
                        </Typography>
                        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                            Build a village of cats and bring them to the moon!
                        </Typography>
                        <div className="landing-row">
                            <Button 
                                href="https://play.google.com/store/apps/details?id=com.nuclearunicorn.kittensgame" variant="contained">
                                    Android
                            </Button>
                            <Button 
                                href="https://itunes.apple.com/us/app/kittens-game/id1198099725?mt=8"
                                variant="contained">
                                    iOS
                            </Button>
                        </div>

                        <div className="landing-row">
                            <a className="play-online" href="/web/">Play online</a>
                        </div>

                        {/*<div className="screenshot">
                            <img className="image" src="/res/img/kg.png"/>
                        </div>*/}
                    </div>
                    
                </div>
                <footer>
                    <Typography variant="overline" align="center" color="textSecondary" component="p" gutterBottom>
                        <a href="/ui/privacy"color="inherit">Privacy</a> | <a href="https://kittensgame.com/blog" color="inherit">Blog</a>
                    </Typography>
                </footer>
            </>
        );
    }
}

class WLandingTab extends React.Component {
    render(){
        return (
            <WLandingPage/>
        );
    }
}

export default WLandingTab;