import {Store, Bus} from "@common/store.js";
import React from "react";

global.CCMStore = new Store();
global.$r = React.createElement;

//todo: define a module and declare imports there
import "@common/jsx/widgets.jsx.js";
import "./jsx/tabs/profile.jsx.js";
import "./jsx/tabs/login.jsx.js";

import "./jsx/viewport.jsx.js";
import Client from "./js/client.js";

global.client = new Client();