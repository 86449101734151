/**
 * Data Source
 */
class DS {
    url = null;
    method = null;

    constructor(url, data) {
        this.url = url;
        this.data = data;
    }

    async get() {
        var response = await fetch(this.url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        return await response.json();
    }

    async post() {
        var response = await fetch(this.url, {
            method: 'post',
            body: JSON.stringify(this.data),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        return await response.json();
    }

    bind(store, mixinKey, method) {
        method = method || "get";
        return this[method]().then(function (data) {
            var mixin = {};
            mixin[mixinKey] = data;
            store.mixin(mixin);

            return data;
        });
    }
}

/**
 * I am a bus, I store messages and notify store of ui changes
 */
class Bus {
    onChangeHandlers = null;

    constructor() {
        this.onChangeHandlers = {};
    }

    bind(event, handler) {
        if (typeof event !== "string"){
            console.warn("event topic must be a string, are you high?");
        }
        if (!this.onChangeHandlers[event]) {
            this.onChangeHandlers[event] = [];
        }
        const listeners = this.onChangeHandlers[event];

        if (handler in listeners) {
            return;
        }
        listeners.push(handler);
    }

    notify(event, opts) {
        for (let topic of Object.keys(this.onChangeHandlers)) {
            if (topic != event) {
                continue;
            }
            for (let i in this.onChangeHandlers[topic]) {
                this.onChangeHandlers[topic][i](opts);
            }
        }
    }

    clear() {
        this.onChangeHandlers = [];
    }
}

global.messages = new Bus();

/**
 * I am a store, I listen to the model bus and update it back and forth
 * I am very simple implementation and model should be probably more complext 
 * (like both store model and update model injected as a component)
 */
class Store {

    stateBus = new Bus();
    store = {};
    _metaCache = {};

    constructor() {
        this.setStore({
            model: null
        });

        messages.bind("mbus/ccm-store/dispatcher", new Proxy(this, this.dispatch));
    }

    dispatch(event, opts) {
        if (event == "attribute-change") {
            this.stateBus.notify("onchange", this.getStore());
            this.updateModel();
        }
    }

    updateModel() {

    }

    setState(store) {
        this.setStore(store);
        this.stateBus.notify("onchange", store);
    }

    notify() {
        this.stateBus.notify("onchange", this.store);
    }

    _merge(src, mixin) {
        var keys = Object.keys(mixin);
        for (var i in keys) {
            var key = keys[i];

            //console.log("merging key", key);
            //new attribute, just copy to target
            if (!src[key]) {
                src[key] = mixin[key];
            } else {
                //uh uh, conflict, if plain primitive than override
                if (!jQuery.isPlainObject(mixin[key])) {
                    src[key] = mixin[key];
                } else {
                    //console.log("collection conflict, merging", src[key], "with", mixin[key]);
                    src[key] = this._merge(src[key], mixin[key]);
                }
            }
        }

        return src;
    }

    mixin(mixin) {

        let store = this.getStore() || {};
        let newStore = this._merge(store, mixin);

        /* TODO: support shallow merge, by default it is deep merge (this might cause more problems than it solves) */
        this.setState(newStore);
    }

    getStore() {
        return this.store;
    }

    setStore(store) {
        this.store = store;
    }

    clear() {
        this.stateBus.clear();
        this.setStore({});
    }
}

export { DS, Store, Bus };
export default DS;