import React from "react";
import {WLoginTab} from "./tabs/login.jsx.js";
import WRegisterTab from "./tabs/register.jsx.js";
import WLandingTab from "./tabs/landing.jsx.js";
import WUserProfileTab from "./tabs/profile.jsx.js";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Snackbar from '@material-ui/core/Snackbar';

var $dd = {
    /**
     * Todo: use proper meta wrapper
     */
    get: function(meta, id, key){
        key = key || "id";

        if (!meta.$metaCache){
            meta.$metaCache = {};
        }

        var val = meta.$metaCache[id];
        if (val){
            return val;
        }

        for (var i in meta){
            if (meta[i][key] == id){
                meta.$metaCache[id] = meta[i];
                return meta[i];
            }
        }
    }
};

class WAuthenticatedMenu extends React.Component {
    state = {
        anchorEl: null,
        isHelpOpened: false
    }

    openMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    showHelp = event => {
        this.setState({ isHelpOpened: true });
    }

    hideHelp = () => {
        this.setState({ isHelpOpened: false });
    }
    
    closeMenu = () => {
        this.setState({ anchorEl: null });
    }

    viewUserProfile = () => {
        this.props.onViewProfile && this.props.onViewProfile();
    }

    logout = async () => {
        this.setState({ anchorEl: null });
        await fetch("/user/logout/", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        CCMStore.setState({});
        this.props.router.navigate('/login');
    }

    render() {
        var store = this.props.store,
            anchorEl = this.state.anchorEl;

        return (
            <header className="navbar navbar-calendar">
                <div className="toolbar">
                    <Typography variant="overline" color="inherit">
                        <div className="menu-container">
                            <div className="menu-logo">
                                Hi, {$r("a", {href:"#", onClick: this.viewUserProfile}, store.user.email )}
                            </div>
                        </div>
                        <div className="mobile-menu-container">
                            <div className="mobile-menu-logo">
                                {
                                    $r("div", {className:"mobile-menu-avatar", href:"#", onClick: this.viewUserProfile},
                                        $r("img", {src: "https://www.gravatar.com/avatar/" + 
                                            (store.user.email ? md5(store.user.email) : "n/a") 
                                        + "?s=30"})
                                    )
                                }
                            </div>
                        </div>
                    </Typography>
                    <div className="grow" />        
                    <IconButton color="inherit" onClick={this.showHelp}>
                        <HelpOutlineIcon className="icon" />
                    </IconButton>
                    <IconButton color="inherit" onClick={this.openMenu}>
                        <MoreIcon className="icon"/>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.closeMenu}
                        >
                        <MenuItem onClick={this.viewUserProfile}>Profile</MenuItem>
                        <MenuItem onClick={this.closeMenu}>Settings</MenuItem>
                        <MenuItem onClick={this.logout}>Logout</MenuItem>
                    </Menu>
                    <Snackbar
                        className="help-block"
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={this.state.isHelpOpened}
                        onClose={this.hideHelp}
                        ContentProps={{
                            'aria-describedby': 'help-block-d',
                        }}
                        message={
                            <>
                                <span id="help-block-d">
                                    <Typography>Poop</Typography>
                                </span>
                            </>
                        }
                    />
                </div>
            </header>
        );
    }
}

class WViewport extends React.Component {
    state = {
        store: {
            server: {
                version: "...",
                motd: "Loading..."
            }
        },
        selectedTabId: "landing"
    }

    static defaultProps = {
        client: null
    }

    getTabs(){
        return [
            {
                id: "login",
                class: WLoginTab
            },
            {
                id: "landing",
                class: WLandingTab
            },
            {
                id: "register",
                class: WRegisterTab
            },
            {
                id: "profile",
                class: WUserProfileTab,
                loginRequired: true
            }
        ];
    }

    componentDidMount() {
        var self = this;
        //CCMStore.stateBus.bind(self.onChange.bind(this));
        CCMStore.stateBus.bind("onchange", self.onChange.bind(this));
        
        //TODO: how about every tab can register itself in the router? ah? ah?

        var router = new Navigo("/ui/", false /*use true for old legacy shit*/, "#!");
        /*router.hooks({
            after: function(params) {
                var url = window.location.pathname;
                mixpanel.track("navigate", {
                    url: url
                });
                if (url == "/ui/register"){
                    mixpanel.track("navigate-register");
                } else if (url == "/ui/login"){
                    mixpanel.track("navigate-login");
                }
            }
        });*/
        router
            .on(function () {
                //console.log("wetpaws was there");
            })
            .on('login', function () {
                self.setState({selectedTabId: "login"});
            })
            .on('landing', function () {
                self.setState({selectedTabId: "landing"});
            })
            .on('register', function () {
                self.setState({selectedTabId: "register"});
            })
            .on('profile', function () {
                self.setState({selectedTabId: "profile"});
            })
            /*.on('calendar*', function () {
                self.setState({selectedTabId: "calendar"});
            })*/
            .resolve();

        this.router = router;    
    }

    onChange(store){
        console.log("on change detected");
        this.setState({store: store});
    }

    navigate = (location) => () => {
        this.router.navigate(location);
    }

    render(){
        var store = this.state.store;
        if (!store || !store){
            return $r("div", {}, "Loading...");
        }
        
        var tabs = this.getTabs(),
            currentTab = $dd.get(tabs, this.state.selectedTabId);

        if (!currentTab){
            currentTab = $dd.get(tabs, "landing");
        } else if (!store.user && currentTab.loginRequired){
            currentTab = $dd.get(tabs, "landing");
        }

        var menu = store.user ?
        /* */
        (
            <WAuthenticatedMenu 
                store={store} 
                router={this.router}
                onViewProfile={this.viewUserProfile}
                onNavigate={this.navigate}/>
        )
        : 
        (
            <header className="navbar">
                <div className="toolbar">
                    <Typography variant="overline" color="inherit">
                        <span style={{cursor:"pointer"}} onClick={this.navigate("/landing")}>Kittens Game</span>
                    </Typography>
                    <div className="grow" />
                    <Button onClick={this.navigate("/register")} color="primary" className="menu-button">
                        Sign up
                    </Button>
                    <Button onClick={this.navigate("/login")} color="primary" className="menu-button login">
                        Log in <ArrowRightAltIcon/>
                    </Button>
                </div>
            </header>
        );

        return (
            <>
                {menu}
                {
                    $r("div", {className: "pages"},
                        $r("div", {
                            className: "page toolbar-fixed",
                            "data-page": "index-left"
                        },
                            $r("div", {className: "page-content"},
                               //-----------------------------------------
                               currentTab ? 
                               $r(currentTab.class, {
                                    store: this.state.store,
                                    client: this.props.client,
                                    viewport: this,
                                    router: this.router
                               }) :
                               $r("div", null, "Unable to load tab '"+ this.state.selectedTabId + "'")
                               //-----------------------------------------
                            )
    
                        )
                    )
                }
            </>
        );
    }

    selectTab(tabId){
        this.router.navigate('/' + tabId);
    }

    login(){
        this.router.navigate('/login');
    }

    viewUserProfile = () => {
        this.selectTab('profile');
    }

    refresh(){
        this.props.client.refresh();
    }
}

export default WViewport;