import React from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';

import {DS} from "@common/store.js";

const styles = theme => ({
});

//todo: break into login page and login form
class WLoginForm extends React.Component {
    state = {
        message: null,
        error: false,
        form:{

        }
    }

    setForm = (elemId) => (e) => {
        this.state.form[elemId] = e.target.value;
        this.setState({form: this.state.form});
    }

    render(){
        return (
            <div className="login-container">
                <div className="paper">
                    <Grid container spacing={24}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>

                            <div className="login-section">
                             
                                <form className="container" noValidate autoComplete="off">
                                <FormControl className="form-control">
                                    <Typography variant="h6" color="inherit">
                                        Sign in
                                    </Typography>
                                    <TextField
                                        id="email"
                                        label="email"
                                        type="email"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.form.email || ""}
                                        onChange={this.setForm("email")}
                                    />
                                    <Typography variant="h6" color="inherit"/>
                                    <TextField
                                        id="password"
                                        label="password"
                                        type="password"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.form.password || ""}
                                        onChange={this.setForm("password")}
                                    />
                                    <Typography variant="h6" color="inherit"/>
                                    <div className="form-control">
                                        {/*<Button color="primary" onClick={this.signin}>
                                            submit
                                        </Button>*/}
                                        <Link onClick={this.signin}>
                                            SUBMIT
                                        </Link>
                                    </div>
                                    <Typography variant="h6" color="inherit"/>
                                    {/*
                                    Find a way for a form to not trigger this.resetPassword event
                                    <Link component="button" onClick={this.resetPassword}>
                                        Forgot password?
                                    </Link>*/}
                                   
                                    {/*<div className="form-control">
                                        <Link href="/ui/register/">Register</Link> 
                                    </div>*/}
                                    <div className="form-control">
                                        <Typography variant="body2" color="inherit">
                                            {
                                            this.state.message && (<FormHelperText error={this.state.error}>{this.state.message}</FormHelperText>)
                                            }
                                        </Typography>
                                    </div>
                                </FormControl>    
                                </form>
                                <Link component="button" onClick={this.resetPassword}>
                                    Forgot password?
                                </Link>
                            </div>
                            
                        {/*
                            $r("div", {style:{ fontSize:"0.8em", bottom: "0px", paddingBottom: "10px", paddingLeft:"10px", position:"absolute"}},
                                "[i] If you are not able to log in, try to clear browser cache")
                        */}
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    
                </div>
            </div>
        );
    }

    signin = async (event) => {
        event.preventDefault();

        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;

        var response = await fetch('/user/login/', {
            method: 'post',
            body: JSON.stringify({email, password}),
            headers: {
                'Content-Type': 'application/json'
            },
        });
        var data = await response.json();

        if (!data.id){
            this.setState({message: data.error, error: true});
        } else {
            new DS("/user/").bind(CCMStore, "user");
        }
    }

    resetPassword = async (event) => {
        event.preventDefault();

        var email = document.getElementById("email").value;

        var response = await fetch('/user/reset/', {
            method: 'post',
            body: JSON.stringify({email}),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        var data = await response.json();
        if (!data.id){
            this.setState({message: data.error, error: true});
        } else {
            this.setState({message: "Please check your email", error: false});
        }
        
    }
};


class WLoginTab extends React.Component {
    componentWillMount() { 
        /*messages.bind("app/login", function(){
            this.props.router.navigate("/landing");
        }.bind(this));*/
    }

    //prohibit login page from being displayed if user have an active session
    componentDidUpdate(){
        var store = this.props.store;
        if (store.user){
            this.props.router.navigate("/landing");
        }
    }

    render(){
        return (
            <WLoginForm/>
        );
    }
};

export {
    WLoginForm,
    WLoginTab
}
export default WLoginTab;