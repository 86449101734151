 /*
->> read
  store.x.y = event.value;
  CCMStore.setState(store);

->> write  
  value : this.props.store.x.y
*/

import React from "react";
import ReactCreateClass from "create-react-class";

//--------------------------------------------
//          TODO: move to CCM
//--------------------------------------------

class WFormElement extends React.Component {
    render(){
        var store = this.props.store;

        return $r(this.props.control, 
            $.extend(this.props, {
                onChange: this.onChange,
                value: store[this.props.attribute]
           })
        );
    }

    onChange(val){
        var store = this.props.store;

        store[this.props.attribute] = val;
        CCMStore.notify();
    }
}

window._form = function(control, props, attribute){
    return $r(WFormElement, 
        $.extend(props, {
            control: control, 
            store: props.store, 
            attribute: attribute
        })
    );
};
//--------------------------------------------

class WTextarea extends React.Component {
    static defaultProps = {
        value: null,
        onChange: null
    }

    render(){
        return $r("input", {
            onChange: this.onChange, 
            onClick: this.onClick, 
            value: this.props.value, 
            style: this.props.style, 
            className: this.props.className,
            placeholder: this.props.placeholder
        });
    }

    onChange(event){
        this.props.onChange(event.target.value);
    }

    onClick(e){
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }
};

window.WTextareaWide = ReactCreateClass({
    getDefaultProps: function(){
        return {
            value: null,
            onChange: null,
            onBlur: null,
            onKeyDown: null,
            autoFocus: false
        };
    },

    render: function(){
        return $r("textarea", {
            onChange: this.onChange,
            onKeyDown: this.onKeyDown,
            onBlur: this.onBlur,
            value: this.props.value, 
            style: this.props.style, 
            className: this.props.className,
            placeholder: this.props.placeholder,
            autoFocus: this.props.autoFocus, 

            autoComplete:"off",
            autoCorrect:"off",
            autoCapitalize:"off",
            spellCheck:"false"
        });
    },

    onChange: function(event){
        this.props.onChange(event.target.value);
    },

    onBlur: function(event){
        this.props.onBlur && this.props.onBlur(event.target.value);
    },

    onKeyDown: function(event){
        this.props.onKeyDown && this.props.onKeyDown(event);
    }
});

window.WCombobox = ReactCreateClass({
    getDefaultProps: function(){
        return {
            value: null,
            options: null,
            onChange: null
        };
    },

    render: function(){
        var opts = [];
        for (var i in this.props.options){
            var opt = this.props.options[i];
            opts.push(
                $r("option", {value: opt.value, selected: (this.props.value === opt.value)}, opt.name)
            );
        }
        return $r("select", {onChange: this.onChange, onClick: this.onClick}, [
            opts
        ]);
    },

    onClick: function(e){
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    },

    onChange: function(e){
        this.props.onChange(e.target.value);
    }
});

window.WCollapsibleSection = ReactCreateClass({
    displayName: "WCollapsibleSection",
    getInitialState: function() {
        return {
            collapsed:
                this.props.collapsed !== undefined ? this.props.collapsed : true,
            buttonGroup: false
        };
    },

    render: function(){
        return $r(WStatelessCollapsibleSection, {
            collapsed: this.state.collapsed,
            disabled: this.props.disabled,
            title: this.props.title,
            ontoggle: this.toggle,
            progress: this.props.progress
        }, this.props.children);
    },

    toggle: function(){
        this.setState({
            collapsed: !this.state.collapsed
        });

        if (this.props.ontoggle) {
            this.props.ontoggle(!this.state.collapsed); 
        }
    }
});


window.WStatelessCollapsibleSection = ReactCreateClass({
    displayName: "WStatelessCollapsibleSection",

    render: function(){
        var sectionClass = "";
        var collapsedClass = "";

        if (this.props.collapsed){
            collapsedClass += " wgt-collapsed";
        }

        return $r("div", {className: "wgt-collapsible" + sectionClass, style: (!this.props.hidden? {}: {display: 'none'})}, [
                $r("div", {
                    className: "collapsible-title",
                    onClick: this.toggle
                },
                    /* ------------------------------
                    A small part of the collapsible title left from the '>' icon that contains title text
                    ---------- ---------------------*/
                    $r("div", {className: "title-content"},
                        [
                            this.props.title.content
                        ]
                    )
                ),
                $r("div", {className: "collapsible-body" + collapsedClass}, this.props.children),
                this.props.progress < 1.0 ? $r("progress", {value: this.props.progress}) : null
        ]);
    },

    titleClick: function(event) {
        if (this.props.disabled){
            //no gain for disabled buttons to do inline handler
            this.toggle();
        }

        event.stopPropagation();
        this.props._title.onClick(event);
    },

    toggle: function(){
        if (this.props.ontoggle) {
            this.props.ontoggle(!this.props.collapsed);
        }
    }
});

/**
 * At last the time has come to do something with a grid
 */

window.WGridRow = ReactCreateClass({
    getDefaultProps: function(){
        return {
            model: null,
            columns: null,
        };
    },

    render: function(){
        var row = this.props.model,
            columns = this.props.columns,
            cells = [];

        if (columns){
            for (var i in columns){
                var column = columns[i],
                    value = column.formatter ? column.formatter(row[column.id]) : row[column.id];
                cells.push( 
                    $r("span", {className: "widgets-grid-cell"}, value)
                );
            }
        }
        return $r("div", {className : "widgets-grid-row"}, [
            cells
        ]);
    }
});

window.WGrid = ReactCreateClass({
    getDefaultProps: function(){
        return {
            model: null,
            columns: null
        };
    },

    render: function(){
        var rows = [],
            model = this.props.model;

        for (var i in model){
            var row = model[i];
            rows.push(
                $r(WGridRow, {model: row, columns: this.props.columns})
            );
        }
        return $r("div", {className: "widgets-grid"}, 
            rows
        );
    }
});

export {WTextarea};