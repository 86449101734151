import React from "react";
import { Typography, Grid, FormControl } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {DS} from "@common/store.js";

class WUserProfileTab extends React.Component {

    state = {
        form:{

        }
    }

    componentDidMount(){
        new DS("/kgnet/save/").bind(CCMStore, "saves");
    }

    setForm = (elemId) => (e) => {
        this.state.form[elemId] = e.target.value;
        this.setState({form: this.state.form});
    }

    render() {
        const {store} = this.props;
        return (
            <div className="paper">
                <Grid container spacing={24}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <form className="container" noValidate autoComplete="off">
                            <FormControl className="form-control">
                                <Typography variant="h6" color="inherit">
                                    Profile
                                </Typography>
                                <div className="form-control">
                                    <Typography variant="subtitle1" color="inherit">
                                        Cloud saves:
                                    </Typography>
                                    {store.saves && (
                                            <Table aria-label="Cloud saves">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Save data</TableCell>
                                                        <TableCell>Last update</TableCell>
                                                        <TableCell>Size</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {
                                                    store.saves.map(save => {
                                                        return (
                                                            <TableRow key={save.guid}>
                                                                <TableCell>
                                                                    {save.index ? 
                                                                    (
                                                                        "Year "+ save.index.calendar.year + ", day " + save.index.calendar.day
                                                                    ) : (
                                                                        <a href={"/kgnet/save/" + save.guid + "/parse/"} target="_blank">Update</a>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{save.timestamp}</TableCell>
                                                                <TableCell>{save.size}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                </TableBody>
                                            </Table>)
                                    }
                                </div>
                            </FormControl>    
                        </form>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
           </div>
        )
    }

    updateIndex = () => {
       //when server responded with parsed save, we can patch record in the collection
       //without reloading the whole data
    }
   
};

export default WUserProfileTab;